import React from 'react';
import './Footer.css';

import {FaFacebookF} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'
import {IoLogoTwitter} from 'react-icons/io'

function Footer() {
  return (
    <footer>
      <a  className='footer__logo' href="#">Sina NAMAKI ARAGHI</a>

      <ul className='permallinks'>
        <li><a href="#">HOME</a></li>  
        <li><a href="#about">ABOUT</a></li>  
        <li><a href="#experience">EXPERIENCE</a></li>  
        <li><a href="#projects">PROJECTS</a></li>  
        <li><a href="#portofolio">PORTOFOLIO</a></li>  
        <li><a href="#publications">PUBLICATIONS</a></li>  
        <li><a href="#contact">CONTACT</a></li>  
      </ul>

      <div className="footer__socials">
        <a href="https://www.facebook.com/sina.namaki/" target="_blank"><FaFacebookF/></a>  
        <a href="https://www.instagram.com/sinasaintzero/" target="_blank"> <FiInstagram/> </a>  
        <a href="https://twitter.com/sinanamakiaragh?lang=en" target="_blank"><IoLogoTwitter/></a>  
      </div>

      <div className="footer__copyright">
        <small>&copy; Sina NAMAKI ARAGHI. All Rights Reserved.</small>
      </div>
    </footer>
  )
}

export default Footer