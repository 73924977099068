import React from 'react';
import './Projects.css';
import {BiCheck} from 'react-icons/bi';

function Projects() {
  return (
    <section id='projects'>
      <h5>What I work on</h5>  
      <h2>Services</h2>
      <div className='container services__container'>
        <article className='service'>
          <div className='service__head'>
            <h3>Scientific Disciplines</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Process Mining</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Statistical Process Control</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Business / Artificial Intelligence</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Enterprise Architecture</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Business Process Management</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Model-Driven Engineering</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>IoT: Indoor/Outdoor Localization Systems</p>
            </li>
           {/* END OF FIRST CARD */}
          </ul>
        </article>
        <article className='service'>
          <div className='service__head'>
            <h3>Data Science/Data Engineering</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>R Programming</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Forecasting</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Network Analytics</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Text Mining</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Web Data Scrapping</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Social Network Analysis</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Data Modeling / Database Design</p>
            </li>
           {/* END OF SECOND CARD */}
          </ul>
        </article>
        <article className='service'>
          <div className='service__head'>
            <h3>Web/Mobile Development</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>React</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>R: BI Dashboard</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>JavaScript </p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p> HTML / CSS</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Java / Android Development</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Stripe API Integration</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Redux/Firebase</p>
            </li>
            
           {/* END OF THIRD CARD */}
          </ul>
        </article>
        <article className='service'>
          <div className='service__head'>
            <h3>Teaching</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>BI and Data Science</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>R: Data Science / Engineering</p>
            </li>
            
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Discrete Event Simulation</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Enterprise Architecture / UML /ERD / BPM</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Supply Chain / Production Management</p>
            </li>
            
           {/* END OF THIRD CARD */}
          </ul>
        </article>
      </div>
    </section>
  )
}

export default Projects;