import React from 'react';
import './Portofolio.css';
import IMG1 from '../../ASSETS/diag.png';
import IMG2 from '../../ASSETS/rio.png';
import IMG8 from '../../ASSETS/disc.png';
import IMG3 from '../../ASSETS/weft.png';
import IMG4 from '../../ASSETS/instagram.png';
import IMG5 from '../../ASSETS/linkedin.png';
import IMG6 from '../../ASSETS/qnaBpmn.png';
import IMG7 from '../../ASSETS/amazon.png';


const data =[
  {
    id:1,
    image:IMG1,
    title: 'DIAG: Process Mining / BI Dashboard',
    github:"https://github.com",
    demo: '',
  },
  {
    id:2,
    image:IMG2,
    title: "R.IO-DIAG: Java Application to Interprete Location Data",
    github:'',
    demo: '',
  },
  {
    id:3,
    image:IMG8,
    title: 'DISC: Business Intelligence Web App to Monitor Containers Transport around the globe',
    github:"https://github.com/SinaSaintZero/DISC-project",
    demo: '',
  },
  {
    id:4,
    image:IMG3,
    title: 'WEFT: Enterprise Social Network App, Real Time Modeling of business processes from social interactions',
    github:"https://code-gi.mines-albi.fr/stash/projects/GIND-PROJECT-DEEPTURTLE/repos/gind-project-deepturtle/browse",
    demo: 'https://todos-da6fe.web.app/landing',
  },
  {
    id:5,
    image:IMG5,
    title: 'Linkedin Clone',
    github:"https://github.com/SinaSaintZero/linkedin-clone-sina",
    demo: '',
  },
  {
    id:5,
    image:IMG7,
    title: 'Amazon Clone',
    github:"https://github.com/SinaSaintZero/AlbiZone",
    demo: '',
  },
  {
    id:5,
    image:IMG4,
    title: 'Instagram Android App Clone',
    github:"https://github.com/SinaSaintZero/InstaCloneYoutube",
    demo: '',
  },
  {
    id:5,
    image:IMG6,
    title: 'BPMN Professor: A Deep Learning (TensorFlow) application to answer questions about BPMN',
    github:"https://github.com/SinaSaintZero/qna-bpmn-app-tensorflow",
    demo: '',
  },
]

const  Portofolio = () => {
  return (
    <section id='portofolio'>
      <h5>My Recent Works</h5>
      <h2>Portofolio</h2>

      <div className="container portofolio__container">
      
        {
          data.map(({id, image, title, github, demo})=>{
            return (
            <article  key={id} className='portofolio__item'>
          <div className="portofolio__item-image">
            <img src={image} alt={title} />  
          </div>
          <h3>{title}</h3>
          <div className="portofolio__item-cta">
          <a href={github} className='btn' target="_blank"> Github</a>
          <a href={demo !== "" ? demo: '#contact'} className='btn btn-primary' target="_blank"> Live Demo</a>  
          </div>
        </article>
            )
          })
        }
        
        
      </div>
    </section>
  )
}

export default Portofolio