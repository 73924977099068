import React from 'react';
import './Experience.css';
import { BsPatchCheckFill } from 'react-icons/bs'

function Experience() {
  return (
    <section id='experience'>
      <h4>The Skills I Have</h4>
      <h2>My Experiences</h2>
      <div className="container experience__container">
        <div className="experience__theory">
          <h3>Theoretical Expertises</h3>
          
          <div className="experience__content">
            <article className='experience__details'>
              <BsPatchCheckFill  className='experience__details-icon'/>
              <div>
                <h4>Process Mining</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill  className='experience__details-icon'/>
              <div>
                <h4>Business Process Management</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill  className='experience__details-icon'/>
              <div>
                <h4>Data Science Topics</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill  className='experience__details-icon'/>
              <div>
                <h4>Statistical Process Control</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill  className='experience__details-icon'/>
              <div>
                <h4>IoT Research</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill  className='experience__details-icon'/>
              <div>
                <h4>Quality Control</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            
          </div>
        </div>

        <div className="experience__dev">
        <h3>Development Expertises</h3>
        
        <div className="experience__content">
            <article className='experience__details'>
                  <BsPatchCheckFill  className='experience__details-icon'/>
                  <div>
                    <h4>React</h4>
                    <small className='text-light'>Experienced</small>
                  </div>
            </article>
            <article className='experience__details'>
                  <BsPatchCheckFill className='experience__details-icon'/>
                  <div>
                  <h4>JavaScript-HTML-CSS</h4>
                  <small className='text-light'>Experienced</small>
                  </div>
            </article>
            <article className='experience__details'>
                  <BsPatchCheckFill  className='experience__details-icon'/>
                  <div>
                  <h4>Java and Android Dev</h4>
                  <small className='text-light'>Intermediate</small>
                  </div>
            </article>
            <article className='experience__details'>
                  <BsPatchCheckFill  className='experience__details-icon'/>
                  <div>
                  <h4>R Programming</h4>
                  <small className='text-light'>Experienced</small>
                  </div>
            </article>
            <article className='experience__details'>
                  <BsPatchCheckFill  className='experience__details-icon'/>
                  <div>
                  <h4>Relational DB: PostgreSql</h4>
                  <small className='text-light'>Experienced</small>
                  </div>
            </article>
            <article className='experience__details'>
                  <BsPatchCheckFill  className='experience__details-icon'/>
                  <div>
                  <h4>Non-Relational DB: Firebase</h4>
                  <small className='text-light'>Experienced</small>
                  </div>
            </article>
            
        </div>

        </div>
      </div>
    </section>
  )
}

export default Experience