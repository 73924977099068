import React from 'react';
import './Header.css';
import CTA from './CTA';
import ME from '../../ASSETS/photo_2022-03-13_16-33-08.jpg';
// import ME from '../../ASSETS/photo_2022-03-13_16-33-06.jpg';
import HeaderSocials from './HeaderSocials';

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Sina NAMAKI ARAGHI, Ph.D.</h1>
        <h5 className="text-light">Associate Professor in Process-Oriented Data Science and Model-Driven Engineering at E.N.I.T (National Engineering School of Tarbes)</h5>  
        <CTA/>
        <HeaderSocials/>

        <div className="me">
          <img src={ME} alt="" />
        </div>
        <a href="#contact" className='scroll__down'>Scroll Down</a>

      </div>
    </header>
  )
}

export default Header