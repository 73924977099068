import React from 'react';
import { BsLinkedin } from 'react-icons/bs';
import { HiAcademicCap } from 'react-icons/hi';
import { FaGithub } from 'react-icons/fa';


const HeaderSocials = () => {
  return (
     <div className="header__socials">
         <a href="https://www.linkedin.com/in/sina-namaki-araghi" target="_blank"> <BsLinkedin/> </a>
         <a href="https://scholar.google.fr/citations?user=mpChjGwAAAAJ&hl=en" target="_blank"> <HiAcademicCap/> </a>
         <a href="https://github.com/SinaSaintZero" target="_blank"> <FaGithub/> </a>
    </div>
  )
}

export default HeaderSocials