import React, { useState } from 'react';
import './Nav.css'
import {AiOutlineHome, AiOutlineUser} from 'react-icons/ai';
import { BiBook, BiMessageSquareDetail} from 'react-icons/bi';
import { RiServiceLine} from 'react-icons/ri';
import { MdCastForEducation} from 'react-icons/md';
import { BsTools} from 'react-icons/bs';
import { FiPhone} from 'react-icons/fi';


 const Nav = () => {
   const [activeNav, setActiveNav] = useState('#')
  return (
    <nav>
      <a href="#" onClick={()=> setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}> <AiOutlineHome/> </a>
      <a href="#about" onClick={()=> setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}> <AiOutlineUser/> </a>
      <a href="#experience"  onClick={()=> setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}> <BsTools/> </a>
      <a href="#projects" onClick={()=> setActiveNav('#projects')} className={activeNav === '#services' ? 'active' : ''}> <RiServiceLine/> </a>
      <a href="#publications" onClick={()=> setActiveNav('#publications')} className={activeNav === '#publications' ? 'active' : ''}> <BiBook/> </a>
      <a href="#contact" onClick={()=> setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}> <FiPhone/> </a>
    </nav>
  )
}

export default Nav;
